<template>
  <v-main>
    <v-container class="mt-md-4">
      <div class="text-h5 mb-2 text-md-h4 mb-md-4">Interner Bereich</div>

      <v-card v-if="roles.includes('ROLE_SALES_ADMIN')">
        <v-card-title>Wählhilfe</v-card-title>
        <v-card-text class="pb-2">Öffne die Wählhilfe auf deinem Handy, um einfach Telefonnummern von der Website zu übertragen.</v-card-text>
        <v-card-actions>
          <v-btn color="primary" text :to="{ name: 'DialAssistant' }">Öffnen</v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="mt-3" v-if="roles.includes('ROLE_SALES_ADMIN')">
        <v-card-title>Vertrieb</v-card-title>
        <v-card-text>
          <div class="d-flex align-baseline flex-wrap mr-n3 mb-n3">
            <v-btn class="mr-3 mb-3" outlined color="" :to="{ name: 'MemberList' }" style="text-transform: none">Mitgliederliste öffnen</v-btn>
            <v-btn class="mr-3 mb-3" outlined :to="{ name: 'EmailTemplateEditor' }" style="text-transform: none">E-Mail-Vorlagen bearbeiten</v-btn>
            <v-btn class="mr-3 mb-3" outlined :to="{ name: 'OutgoingEmails' }" style="text-transform: none">E-Mail Ausgang</v-btn>
            <v-btn class="mr-3 mb-3" outlined :to="{ name: 'ProcessChains' }" style="text-transform: none">Prozessketten verwalten</v-btn>
            <v-btn class="mr-3 mb-3" outlined :to="{ name: 'ContactLists' }" style="text-transform: none">Kontaktlisten</v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="roles.includes('ROLE_SALES_ADMIN')">
        <v-card-title>Roy</v-card-title>
        <v-card-text>
          <div class="d-flex align-baseline flex-wrap mr-n3 mb-n3">
            <v-btn class="mr-3 mb-3" outlined color="" :to="{ name: 'Jahresupdate' }" style="text-transform: none">Jahresupdate</v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="roles.includes('ROLE_SALES_ADMIN_')">
        <v-card-title>Kontaktlisten</v-card-title>
        <v-card-text>
          <v-btn style="text-transform: none" outlined color="primary" to="/internal-area/contact-lists/1">Liste 1</v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="roles.includes('ROLE_LESSON_ADMIN')">
        <v-card-title>Vimeo-Daten synchronisieren</v-card-title>
        <v-card-text>
          <v-btn style="text-transform2: none2" outlined color="primary" @click="synchronizeVimeoData">Synchronisieren</v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="roles.includes('ROLE_LESSON_ADMIN')">
        <v-card-title>Kurse bearbeiten</v-card-title>
        <v-card-text>
          <v-btn outlined color="primary" :to="{ name: 'CourseEditor' }">Kurse bearbeiten</v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="roles.includes('ROLE_LESSON_ADMIN')">
        <v-card-title>Formulare bearbeiten</v-card-title>
        <v-card-text>
          <v-btn outlined color="primary" :to="{ name: 'FormEditor' }">Formulare bearbeiten</v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mt-3">
        <v-card-title>Links</v-card-title>
        <v-card-text>
          <v-btn text color="primary" :to="{ name: 'PersonalityTest' }">Persönlichkeitstest</v-btn>
          <v-btn text color="primary" :to="{ name: 'AI' }">AI Matchmaking</v-btn>
        </v-card-text>
      </v-card>


    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "InternalArea",
  methods: {
    synchronizeVimeoData() {
      this.$store.dispatch("putApplicationDataSingleEntry", ["video-administration/synchronize-vimeo-metadata"])
    }
  },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
  }
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>